var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box1"},[_c('div',{staticClass:"w3-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('i',{staticClass:"fa fa-home w3-xxlarge houseFontColor w3-hover-text-gray w3-padding-small"})]),_c('i',{staticClass:"fa fa-save w3-xxlarge houseFontColor w3-hover-text-gray w3-padding-small",on:{"click":_vm.saveMood}}),_c('i',{staticClass:"fa fa-share w3-xxlarge houseFontColor w3-hover-text-gray w3-padding-small"})],1)]),_c('div',{staticClass:"box2"},[_c('v-stage',{ref:"stage",attrs:{"config":_vm.configKonva},on:{"dragstart":_vm.handleDragstart,"dragend":_vm.handleDragend,"mousemove":_vm.handleMouseMove,"mousedown":_vm.handleStageMouseDown}},[(_vm.image)?_c('v-layer',{ref:"layer"},[_vm._l((_vm.list),function(item){return _c('v-image',{key:item.id,attrs:{"config":{
              name: item.name,
              rotation: item.rotation,
              id: item.id,
              src: item.src,
              image: item.image,
              scaleX: item.scaleX,
              scaleY: item.scaleY,
              x: item.x,
              y: item.y,
              draggable: true,
            }},on:{"transformend":_vm.handleTransformEnd}})}),_c('v-transformer',{ref:"transformer"})],2):_vm._e()],1)],1),_c('div',{staticClass:"box3"},[_c('h2',[_c('center',[_vm._v("Produkte")])],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.options),function(option){return _c('option',{key:option.text,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('div',{ref:"imglist",staticClass:"w3-row roll"},_vm._l((_vm.productList),function(img){return _c('div',{key:img.id,staticClass:"w3-third"},[(img.type === _vm.selected || _vm.selected == 'all')?_c('img',{ref:"layer",refInFor:true,attrs:{"src":img.url,"id":0,"position":img.position,"draggable":true},on:{"dragstart":_vm.handleDragstart,"dragend":_vm.handleDragend}}):_vm._e()])}),0)])]),_c('br'),_c('center',[_c('b',[_vm._v("Gesamtpreis: "+_vm._s(_vm._f("number")(_vm.sum,'0.00'))+" €")])]),_c('center',[_c('p',[_vm._v(_vm._s(_vm.list))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }