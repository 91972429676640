<template>
  <div>
    <div class="box">
      <!-- Left Column -->
      <div class="box1">
        <div class="w3-center">
           <router-link to="/"><i class="fa fa-home w3-xxlarge houseFontColor w3-hover-text-gray w3-padding-small"></i></router-link>
          <i class="fa fa-save w3-xxlarge houseFontColor w3-hover-text-gray w3-padding-small" @click="saveMood"></i>
          <i class="fa fa-share w3-xxlarge houseFontColor w3-hover-text-gray w3-padding-small"></i>
        </div>
      </div>
      <!-- Canvas Column -->
      <div class="box2">
        <v-stage
          ref="stage"
          :config="configKonva"
          @dragstart="handleDragstart"
          @dragend="handleDragend"
          @mousemove="handleMouseMove"
          @mousedown="handleStageMouseDown"
        >
          <v-layer ref="layer" v-if="image">
            <v-image
              v-for="item in list"
              :key="item.id"
              :config="{
                name: item.name,
                rotation: item.rotation,
                id: item.id,
                src: item.src,
                image: item.image,
                scaleX: item.scaleX,
                scaleY: item.scaleY,
                x: item.x,
                y: item.y,
                draggable: true,
              }"
              @transformend="handleTransformEnd"
            />
            <v-transformer ref="transformer" />
          </v-layer>
        </v-stage>
      </div>
      <!-- Right Column -->
      <div class="box3">
        <h2><center>Produkte</center></h2>
        <select v-model="selected">
          <option v-for="option in options" :key="option.text" :value="option.value">
            {{ option.text }}
          </option>
        </select>
        <div ref="imglist" class="w3-row roll">
          <div class="w3-third" v-for="img in productList" :key="img.id">        
            <img v-if="img.type === selected || selected == 'all'"
              :src="img.url"
              :id="0"
              :position="img.position"
              v-bind:draggable="true"
              ref="layer"
              @dragstart="handleDragstart"
              @dragend="handleDragend"
            />
          </div>
        </div>
      </div>
    </div>
    <br>
    <center>
      <b>Gesamtpreis: {{ sum |number('0.00') }} €</b>
    </center>
    <!--<br /><br />
    <center>
      <p>{{ mousePos }}</p>
    </center>
    <br />-->
    <center>
      <p>{{ list }}</p>
    </center>
  </div>
</template>

<script>
const width = 768;//680
const height = 768;
export default {
  name: "Mood",
  data() {
    return {
      moodList:[],
      productList: [
        { id: 1, type: "shoes",    title: "Stiefel",   price: 149.95, url: "https://www.kaiser-mode.de/fileadmin/_processed_/5/4/csm_1-hip-damen-schuh-d1468-schwarz-14995-_Marketing_Format_4eeb1b1a51.png" },
        { id: 2, type: "pants",    title: "Hose",      price: 150.0,  url: "https://www.kaiser-mode.de/fileadmin/_processed_/b/b/csm_70244_8272_ec0e130e48.jpg" },
        { id: 3, type: "jacket",   title: "Jacke",     price: 350.0,  url: "https://www.kaiser-mode.de/fileadmin/_processed_/7/9/csm_70541_9990_2c76303cf8.jpg" },
        { id: 4, type: "pullower", title: "Pullower",  price: 64.95,  url: "https://www.kaiser-mode.de/fileadmin/_processed_/4/a/csm_1-msch-damen-pullover-14297erica-egret-5995-_Marketing_Format_29dbc77b27.png" },
        { id: 5, type: "shirt",    title: "Hemdbluse", price: 59.95,  url: "https://www.kaiser-mode.de/fileadmin/_processed_/b/5/csm_BRAX_99_456067_94119600_417722c44a.jpg" },
        { id: 6, type: "pullower", title: "Pullower2", price: 119.95, url: "https://www.kaiser-mode.de/fileadmin/_processed_/2/5/csm_BRAX_66_359067_90129900_8397ba5d29.jpg" },
        { id: 7, type: "pullower", title: "Pullower3", price: 99.95,  url: "https://www.kaiser-mode.de/fileadmin/_processed_/d/5/csm_BRAX_64_352067_90131700_bcfeba4e17.jpg" },
        { id: 8, type: "jacket",   title: "Jacke2",    price: 199.95, url: "https://www.kaiser-mode.de/fileadmin/_processed_/0/5/csm_BRAX_66_956347_95964040_8812570f89.jpg" },
        { id: 9, type: "dress",    title: "Kleid",     price: 299.00, url: "https://www.kaiser-mode.de/fileadmin/_processed_/d/0/csm_VM-by-Vera-M_201_4388_201-80234990_11840fd254.jpg" },
        { id:10, type: "pants",    title: "Culotte",   price: 119.95, url: "https://www.kaiser-mode.de/fileadmin/_processed_/8/f/csm_v-damen-marc-aurel-hose-rot-kaiser-mode_Marketing_Format_2f59556f08.png" },
        { id:11, type: "pullower", title: "Bluse",     price: 59.99,  url: "https://www.kaiser-mode.de/fileadmin/_processed_/a/2/csm_1_more_more_damen_bluse_01082053_4041_kaiser-mode_Marketing_Format_e36784c27c.png" },
        { id:12, type: "shirt",    title: "Shirt",     price: 79.95,  url: "https://www.kaiser-mode.de/fileadmin/_processed_/1/c/csm_v-marc-aurel-damen-tshirt-pink-7995-kaiser-mode_Marketing_Format_edb269ad20.png" },
        { id:13, type: "shoes",    title: "Stiefel",   price: 895.00, url: "https://www.kaiser-mode.de/fileadmin/_processed_/1/c/csm_1-chloe-damen-schuh-chc17a13418-21v-895-_kaiser-mode_7005f22120.png" },
        { id:14, type: "shoes",    title: "Pumps",     price: 550.00, url: "https://www.kaiser-mode.de/fileadmin/_processed_/9/0/csm_1-tods-damen-schuh-xxw84c0de60-r822-550-_kaiser-mode_a93b295e4b.png" },
      ],
      list: [],
      options: [
        { text: 'Alles', value: 'all' },
        { text: 'Schuhe', value: 'shoes' },
        { text: 'Jacken', value: 'jacket' },
        { text: 'Pullower', value: 'pullower' },
        { text: 'Shirts', value: 'shirt' },
        { text: 'Kleider', value: 'dress' },
      ],
      selected: "all",
      price: 0,
      sum: 0,
      id: 0,
      flag: false,
      itemURL: "",
      dragItemId: null,
      configKonva: {
        width: width,
        height: height,
      },
      image: null,
      screenshot: null,
      mousePos: {
        x: 0,
        y: 0,
      },
      mousePosNew: {
        x: 30,
        y: 400,
      },
      cImage: null,
      selectedShapeName: ""
    };
  },
  methods: {
    saveMood(){
      const parsed = JSON.stringify(this.list);
      localStorage.setItem('list', parsed);
      this.screenshot = this.$refs.stage.getNode().toDataURL();
      //console.log(this.$refs.stage.getNode().toDataURL());
      console.log("saved");
    },

    handleMouseMove() {
      this.mousePos = this.$refs.stage.getNode().getPointerPosition();
    },

    handleTransformEnd(e) {
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const rect = this.list.find(
        (r) => r.name === this.selectedShapeName
      );
      //const item = this.list.find(i => i.id === this.dragItemId);
      const index = this.list.indexOf(rect);
      this.list.splice(index, 1);
      this.list.push({
        id: rect.id,
        name: "rect" + rect.id,
        x: e.target.x(),
        y: e.target.y(),
        width: rect.width,
        height: rect.height,
        scaleX: e.target.scaleX(),
        scaleY: e.target.scaleY(),
        image: rect.image,
        rotation: e.target.rotation(),
        price: rect.price,
        src:rect.src
      }); 
    },

    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = "";
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }
      // find clicked rect by its name
      const name = e.target.name();
      const rect = this.list.find((r) => r.name === name);
      if (rect) {
        this.selectedShapeName = name;
      } else {
        this.selectedShapeName = "";
      }
      this.updateTransformer();
    },

    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      const selectedNode = stage.findOne("." + selectedShapeName);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
      transformerNode.getLayer().batchDraw();
    },

    handleDragstart(e) {
      console.log("DRAG");
      
      // save drag element:
      if (this.list.length > 0 && e.target.id !=0 ) {
        this.dragItemId = e.target.id();
      }
      this.itemURL = e.target.src;
    },
    handleDragend(e) {
      if (this.list.length > 0 && e.target.id !=0 ) {
        this.mousePosNew.x = e.target.x();
        this.mousePosNew.y = e.target.y();
      }
      console.log("DROP");
      this.mousePos = this.$refs.stage.getNode().setPointersPositions(e);
      this.mousePos = this.$refs.stage.getNode().getPointerPosition();
      const image = new window.Image();
      image.src = this.itemURL;
      image.width = image.width * 0.15;
      image.height = image.height * 0.15;  
      image.scaleX = 1;
      image.scaleY = 1;   
      image.position = this.mousePos;
      image.rotation = 0;
      //image.filters = Konva
      image.onload = () => {
        // set image only when it is loaded
        this.image = image;
        if (!isNaN(this.mousePos.x)) {
          for (let index = 0; index < this.productList.length; index++) {
            const element = this.productList[index];
            if(element.url === this.image.src){
              this.price = element.price;
              this.sum = this.sum + element.price;
            }
          }
          this.list.push({
            id: Math.round(Math.random() * 10000).toString(),
            name: "rect" + Math.round(Math.random() * 10000).toString(),
            x: this.image.position.x - 60,
            y: this.image.position.y - 75,
            width: this.image.width,
            height: this.image.height,
            scaleX: this.image.scaleX,
            scaleY: this.image.scaleY,
            image: this.image,
            rotation: this.image.rotation,
            price: this.price,
            src: this.image.src
          });  
        }
      };

      if(this.dragItemId != null){
          const item = this.list.find(i => i.id === this.dragItemId);
          const index = this.list.indexOf(item);
          this.list.splice(index, 1);
          this.mousePos = this.$refs.stage.getNode().getPointerPosition();
          //console.log(this.mousePosNew);
          this.list.push({
            id: this.dragItemId,
            name: "rect" + this.dragItemId,
            x: this.mousePosNew.x,
            y: this.mousePosNew.y,
            width: item.width,
            height: item.height,
            scaleX: item.scaleX,
            scaleY: item.scaleY,
            image: item.image,
            rotation: item.rotation,
            price: item.price,
            src:item.src
          }); 
        }
      this.dragItemId = null;
      this.itemURL = null;
    },
    async loadOneImage(image){
      this.image = await image;
    },
    async loadImages(){
      if (localStorage.getItem('list')) {
        try {
          const tempList = JSON.parse(localStorage.getItem('list'));
          for (let i = 0; i < tempList.length; i++) {
            const image = new window.Image();
            image.src = tempList[i].src;
            image.width = tempList[i].width;
            image.height = tempList[i].height;     
            image.position = {x:tempList[i].x, y:tempList[i].y};
            await this.loadOneImage(image);
            tempList[i].image = image
            this.sum = this.sum + tempList[i].price;
          }
          this.list = tempList;

        } catch(e) {
          console.error(e)
          //localStorage.removeItem('list');
        }
      }
    },
  },
  created() {
    this.loadImages();
  },
  mounted(){
    this.loadImages();
  }
};
</script>

<style>
.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box1 {
  width: 50px;
  height: 768px;
  justify-content: center;
  background-color: white;
}

.box2 {
  width: 768px;
  height: 768px;
  background-color: white;
  margin-left: 20px;
}

.box3 {
  width: 400px;
  height: 768px;
  background-color: white;
  margin-left: 20px;
}

.container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: auto;
  /* for Firefox */
  height: 660px;
  min-height: 0;
}

.imgcontainer{
  flex:50%;
  background-color: #fff8ef; 
}

.imgcontainer img{
    height: 170px;
    width: auto;  
    margin: auto;
    display: block; 
}

img{
  mix-blend-mode: multiply;
  width: auto;
  height:150px;
  align-content: center;
}

.roll{
  overflow-y: auto;
   height: 660px;
}
</style>

